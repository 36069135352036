<template>
  <div>
    <slot
      name="default"
      v-bind="{hasUndo, hasRedo, undo, redo, clear}"
    ></slot>
  </div>
</template>

<script>
export default {
  name: 'ui-history',

  props: {
    name: {
      type: String,
      required: true
    },

    value: {
      required: true
    },

    debounce: {
      type: Number,
      required: false,
      default: 500
    }
  },

  data() {
    return {
      history: [],
      pointer: -1,
      ignoreNextChange: false,
      debounceTimeout: null
    };
  },

  watch: {
    value: {
      immediate: false,
      handler(newValue) {
        if (this.ignoreNextChange) {
          this.ignoreNextChange = false;
          return;
        }
        this.pushChange(newValue);
      }
    }
  },

  computed: {
    hasUndo() {
      return this.pointer > 0;
    },

    hasRedo() {
      return this.pointer < this.history.length - 1;
    }
  },

  mounted() {
    let stored = JSON.parse(localStorage.getItem(`history:${this.name}`));
    if (stored && Array.isArray(stored.history)) {
      this.history = stored.history;
      this.pointer = stored.pointer;
    } else {
      this.pushChange(this.value);
    }
  },

  methods: {
    undo() {
      if (typeof this.history[this.pointer - 1] != 'undefined') {
        this.pointer--;
        this.ignoreNextChange = true;
        this.persist();
        this.$emit(
          'input',
          JSON.parse(JSON.stringify(this.history[this.pointer].value))
        );
      }
    },

    redo() {
      if (typeof this.history[this.pointer + 1] != 'undefined') {
        this.pointer++;
        this.ignoreNextChange = true;
        this.persist();
        this.$emit(
          'input',
          JSON.parse(JSON.stringify(this.history[this.pointer].value))
        );
      }
    },

    pushChange(newValue) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        // Delete all items from the pointer onwards
        if (this.pointer > 0) {
          this.history.length = this.pointer + 1;
        }

        this.history.push({
          timestamp: Math.floor(new Date().getTime() / 1000),
          value: JSON.parse(JSON.stringify(newValue))
        });

        this.pointer = this.history.length - 1;
        this.persist();
      }, this.debounce);
    },

    persist() {
      localStorage.setItem(
        `history:${this.name}`,
        JSON.stringify({ history: this.history, pointer: this.pointer })
      );
    },

    clear() {
      this.history = [];
      this.pointer = -1;
      localStorage.removeItem(`history:${this.name}`);
    }
  }
};
</script>